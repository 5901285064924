<template>
  <div class="page">
    <!-- orgId==1756 -->
    <div class="flex">
      <div class="account cur" @click="titChange(1)">平台台账</div>
      <div class="account cur" @click="titChange(2)">APP台账</div>
      <div class="account cur account-active" @click="titChange(3)">已注销台账</div>
    </div>
    <!-- <div class="account" v-else>平台台账</div> -->
    <!-- 筛选 -->
    <div class="selectBox">
      <!-- 平台类型 -->
      <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
      <!-- 主体类型 -->
      <PlatformCom ref="body_type" :desc="'body_type'"></PlatformCom>
      <el-row style=" position: relative;">
        <el-col :span="23" class="selectBox-group-left">
          <!-- 选择的条件 -->
          <div class="selectBox-group-item">
            <el-select class="w120" v-model="myInfo.searchType" placeholder="请选择">
              <el-option v-for="item in myInfo.searchList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input class="w180 m-r-8 m-l-8" v-model="myInfo.content" clearable placeholder="请输入"></el-input>
          </div>
        </el-col>
        <el-col :span="1">
          <div class="selectBox-group-btn" style="right:10px">
            <div class="queryBtn" @click="toSearch">查询</div>
            <div class="resetBtn" @click="resetFn">重置</div>
            <!-- 需要注释,目前不需要该功能 -->
            <!-- <div class="exportBtn" @click="importData">
              <img src="@/assets/img/wang/import.png" alt="" />
              <span>数据导入</span>
            </div> -->
            <div class="exportBtn m-l-16" @click="exportDakai">
              <img src="@/assets/img/wang/export.png" alt="" />
              <span>数据导出</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新增账号 -->
    <div class="account-number">
      <!-- <Sort ref="sort" @sortFn="toSearch"></Sort> -->
      <!-- 需要注释,目前不需要该功能 -->
      <!-- <div class="add-account" @click="newAccount">
        <img src="@/assets/img/wang/add.png" />
        <span>新增账号</span>
      </div> -->
    </div>

    <!-- 列表 -->
    <div class="data-list">
      <!-- 加载中 -->
      <el-skeleton :rows="26" :loading="ifOpen" />
      <div class="data-box" v-for="(item, index) in queryList" :key="index">
        <div class="data-box-left">
          <el-image :src="item.headImage">
            <div slot="error" class="image-slot">
              <img src="@/assets/img/zhanwei2.png" alt="" srcset="" />
            </div>
          </el-image>
          <div class="platform-type">{{ item.platformTypeName }}</div>
        </div>
        <div class="data-box-right">
          <div class="name-box">
            <template>
              <img v-if="item.gatherLevel==1" src="@/assets/img/zhang/zhongdian.png" class="m-r-6" alt="">
              <div class="name" @click="toDetail(1, item)" v-html="item.accountName ? item.accountName : ''" />
            </template>

            <div class="bad-num cursor" @click="toDetail(4, item)">
              有害信息({{ item.harmfulCnt }})
            </div>
          </div>
          <div class="data-info">
            <div>平台状态：</div>
            <template v-if="item.messageTypeNameList.length > 0">
              <span class="colRed">{{ item.messageTypeNameList[0] + "   " }}
              </span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" v-html="item.messageTypeNameDesc"></div>
                <span>(更多)</span>
              </el-tooltip>
            </template>
            <div class="status" v-else>正常</div>
            <div class="region m-l-16">
              所属区域：{{ $main.ifText(item.areaName) }}
            </div>
            <div class="subject-type">
              <!-- 主体类型：{{ $main.ifText(item.bodyTypeName) }} -->
              主体类型：
              <el-select v-model="item.bodyType" placeholder="请选择" size="mini" style="width:100px" @change="editBodyType($event,item)">
                <el-option v-for="item2 in bodyTypeList" :key="item2.value" :label="item2.label" :value="item2.value">
                </el-option>
              </el-select>
            </div>
            <div class="region" v-if="item.platformType == 1">
              主办单位：{{ $main.ifText(item.companyName) }}
            </div>
            <!-- <div class="company" v-else>
              作者：{{ $main.ifText(item.accountName) }}
            </div> -->
            <div class="region" v-if="item.platformType == 1">
              ICP备案：{{ $main.ifText(item.icpNumber) }}
            </div>
            <template v-else>
              <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,2)">粉丝数：{{ $main.ifText(item.fans) }}</div>
              <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,3)">关注数：{{ $main.ifText(item.attention) }}</div>
            </template>

            <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,1)">
              发文数：{{ item.contentCount }}
            </div>
          </div>
          <div class="operation">
            <div class="operation-left">
              <span>标签 </span>
              <el-select class="select" v-model="item.labelId" placeholder="请选择" @change="ChangeFn(item, $event)">
                <el-option v-for="item2 in infoLabelList" :key="item2.value" :label="item2.label" :value="item2.value">
                </el-option>
              </el-select>
            </div>
            <div class="operation-right">
              <div class="remove" @click="changeCancel(0, item)">
                <!-- <img src="@/assets/img/wang/detail.png" alt="" /> -->
                <i class="el-icon-remove-outline"></i>
                <div class="m-l-4">移除</div>
              </div>
              <div class="detail" @click="toDetail(1, item)">
                <img src="@/assets/img/wang/detail.png" alt="" />
                <div>查看详情</div>
              </div>
              <div class="detail" @click="toDetail(2, item)">
                <img src="@/assets/img/wang/content-info.png" alt="" />
                <div>内容信息</div>
              </div>
              <!-- <div class="programme" @click="toDetail(3, item)">
                <img src="@/assets/img/wang/wenjianjia.png" alt="" />
                <div>方案信息</div>
              </div> -->
              <div class="remove" @click="deleteFnMy(item)">
                <img src="@/assets/img/wang/rev.png" alt="" />
                <div>删除</div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <img v-if="item.isCancelled==3" class="disposed" src="../../../assets/img/wang/yizhuxiao.png" alt="" />
      </div>
      <!-- 位图 -->
      <Bitmap :desc="'列表'" v-if="queryList.length == 0&&!ifOpen" />
      <Pagination :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange">
      </Pagination>
    </div>
    <!-- 删除弹出框 -->
    <DeleteDialog ref="openDialog" @deleteFn="deleteaccount"></DeleteDialog>
    <!-- 导出弹出框 -->
    <exportDialog ref="exportDialog"></exportDialog>
    <!-- 导入弹出框 -->
    <importDialog @close="importClose" :matrixId="id" :dialogFormVisible="dialogFormVisible"></importDialog>
  </div>
</template>

<script>
import LabelCom from "@/components/dressing/labelCom.vue"; // 筛选栏标签选择器
import MonthlyIssue from "@/components/dressing/MonthlyIssue.vue"; // 月发文数量
import Fans from "@/components/dressing/Fans.vue"; // 粉丝
import MessageType from "@/components/dressing/MessageType.vue"; // 平台状态
import PlatformCom from "@/components/dressing/PlatformCom.vue"; //  筛选框 全选多选
import importDialog from "../component/importDialog.vue";       // 导入
import Sort from "../../Problem/components/sort.vue";            // 排序
import AreaMany from "@/components/areaMany.vue";        // 地区多选
import exportDialog from "@/components/exportDialog.vue"; // 导出弹框 
import Pagination from "@/components/pagination.vue"; // 分页
import Bitmap from "@/components/bitmap.vue"; // 位图
import DeleteDialog from "@/components/deleteDialog.vue";
import {
  queryPage,
  getList,
  account,
  deleteaccount,
  exportExcel,
  update,
} from "@/api/platformaccount/index";
export default {
  components: {
    Pagination,          // 分页
    Bitmap,             // 位图
    DeleteDialog,      // 删除弹出框子
    exportDialog,      // 导出
    Sort,            // 排序
    AreaMany,        // 地区多选
    importDialog,   // 导入框
    PlatformCom,  // 多选框
    MessageType, // 有害信息类型
    Fans,   //粉丝
    MonthlyIssue,// 月发文数量
    LabelCom, // 筛选栏标签选择器
  },
  data() {
    return {
      //列表主体类型
      bodyTypeList: [],
      // 筛选栏目的信息
      myInfo: {
        contentCount: {
          cnt: null,
          above: true,
        },
        textNum: null, //月发文数量
        textSection: true, //月发文数量区间
        searchType: 0, //搜索类型
        searchList: [
          { label: "名称", value: 0 },
          { label: "域名/url", value: 1 },
          { label: "主办单位", value: 2 },
        ],
        isCancelled: '',
        cancelledList: [
          { label: "全部", value: 2 },
          { label: "否", value: 0 },
          { label: "是", value: 1 },
        ],
        gatherLevel: '',
        gatherLevelList: [
          { label: "是", value: 1 },
          { label: "否", value: 0 },
        ],
        content: "", //搜索内容
        address: "", //所在地区
        addressList: [], //地区
        label: [], //已选标签
        labelList: [],
      },

      isCopy_myInfo: {}, // 拷贝的对象
      infoLabel: "", //列表信息标签
      infoLabelList: [],
      queryList: [], // 列表
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 100,
      ifOpen: true, // 加载中
      dialogFormVisible: false,
      id: null, //属地前台详情id
      orgId: ''
    };
  },
  created() {
    this.getList(); // 标签列表
    this.orgId = JSON.parse(localStorage.getItem('user')).orgId

  },
  mounted() {
    this.getQueryPage(); // 列表
    this.bodyTypeList = JSON.parse(localStorage.getItem('body_type'))
    this.bodyTypeList.push({ label: '未知', value: '0' })
  },
  methods: {
    async changeCancel(val, item){
      console.log(val, item);
      let data = {
        isCancelled: val,
        accountId: item.accountId,
        platformType: item.platformType
      }
      let res = await update(data)
      if (res.data.code == 200) {
        this.$message.success('修改成功')
        setTimeout(() => {
          this.toSearch();
        }, 1000);
      }
    },
    titChange(val) {
      if (val == 1) {
        this.$main.routeResolve('/platformaccount')
      } else if (val == 2) {
        this.$main.routeResolve('/appAccount')
      } else if (val == 3) {
        this.$main.routeResolve('/cancelAccount')
      }
    },
    //改变主体类型
    async editBodyType(e, item) {
      console.log(e, item);
      let data = {
        bodyType: e,
        accountId: item.accountId,
        platformType: item.platformType
      }
      let res = await update(data)
      if (res.data.code == 200) {
        this.$message.success('修改成功')
      }
    },
    //导入数据
    importData() {
      this.dialogFormVisible = true;
    },
    //关闭导入
    importClose() {
      this.dialogFormVisible = false;
    },
    exportDakai() {
      const { toSearch, page } = this;
      this.exportFN({ ...toSearch({ ifReturn: 1 }), ...page });
    },
    // 导出
    async exportFN(obj) {
      if (obj.platformTypeList.length != 1) {
        this.$message.warning('目前只支持单平台导出,请重新操作')
        return
      }
      //                        请求函数，参数配置,组件配置                           后面的1代表新的导出
      await this.$main.exportFN(exportExcel, obj, this.$refs, 1, '平台台账导出.xlsx', 1);
    },
    // 打开删除框
    deleteFnMy(item) {
      item.message = "这条数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 执行删除请求
    async deleteaccount({ item: { accountId, platformType } }) {
      const Res = await deleteaccount({ accountId, platformType });
      if (Res.data.code == 200) {
        this.$message({
          message: `操作成功`,
          type: "success",
        });
        await this.$main.mySetTimeOut(2000);
        this.toSearch();
      } else {
        this.$message.error("操作失败!");
      }
    },
    // 列表 && 分页  请求
    async getQueryPage(myInfo) {
      console.log(myInfo);
      this.ifOpen = true;
      this.queryList = [];
      try {
        myInfo = { ...myInfo }; // 这是选择器
        console.log(myInfo);
        const Res = await queryPage(
          myInfo ? { ...myInfo, ...this.page, statHarm: true,isCancelled:3 } : { ...this.page, statHarm: true }
        );
        Res.data.data.list.forEach((v) => {
          if (!v.bodyType) v.bodyType = 0
          v.bodyType = v.bodyType + ''
          if (v.messageTypeNameList.length > 0) {
            v.messageTypeNameDesc = v.messageTypeNameList
              .join(" ")
              .replace(new RegExp(" ", "g"), "<br/>");
          }
          // eslint-disable-next-line no-undef
          // v.headImage =  require(v.headImage)
        });
        this.queryList = Res.data.data.list;
        this.total = Number(Res.data.data.totalCount);
        this.ifOpen = false;
      } catch {
        this.ifOpen = false;
      }
    },
    // 标签列表 请求
    async getList() {
      const Res = await getList({});
      // const {
      //   data: { list },
      // } = this.$main.getResData(Res);
      if (Res && Res.data && Res.data.code == 200) {
        this.infoLabelList = Res.data.data.list.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }

    },
    // 列表标签 方法
    async ChangeFn(item, e) {
      console.log(item, e);
      const { accountId, platformType, labelIds } = item;
      const Res = await account({
        labelIds: labelIds ? labelIds + "," + e : e,
        accountId,
        platformType,
      });
      const { code } = Res.data;
      if (code == 200) {
        this.$message({
          message: `修改标签成功`,
          type: "success",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    //新增账号
    newAccount() {
      this.$router.push({ path: "/newAccount" });
    },
    // 分页  页码
    CurrentChange(val) {
      this.page.pageNo = val;
      this.toSearch({ ifpageType: 1 });
    },
    // 分页  条数
    SizeChange(val) {
      this.page.pageSize = val;
      // this.page.pageNo =1
      this.toSearch();
    },
    //详情
    toDetail(inx, item) {
      // 1 平台台账详情 accountDetail
      // 2 发布内容列表 publishingcontent
      // 3 监测方案列表 monitor
      // 4 有害信息列表 harmful
      const routeObj = {
        1: { path: "/accountDetail", query: item },
        2: { path: "/publishingcontent", query: { ...item, pageType: 1 } },
        3: { path: "/monitor", query: { ...item, pageType: 1 } },
        4: { path: "/harmful", query: { ...item, pageType: 1 } },
      }[inx];
      const { path, query } = routeObj;
      this.$main.routeResolve(path, query);
    },
    // 查询
    toSearch({ ifReturn, ifpageType } = {}) {
      //  type 存在 代表会返回成对象
      //  pageType 存在 代表是分页按钮点击的  如果不存在 那么分页默认第一页
      if (!ifpageType) {
        this.page.pageNo = 1
      }
      const { myInfo, $refs } = this;
      const obj = {
        platformTypeList: $refs.platform_type.formObj.checkedform.map((v) => v.value), // 平台类型
        bodyTypeList: $refs.body_type.formObj.checkedform.map((v) => v.value), // 主体类型
        isCancelled:3,//所有已注销
        // messageTypeList: $refs.MessageType.status, // 平台状态
        // labelList: $refs.LabelCom.label,
        // authenticateList: $refs.is_authenticate.formObj.checkedform.map(
        //   (v) => v.value
        // ),
      };
      // 粉丝
      // if ($refs.Fans.fans.cnt && $refs.Fans.fans.cnt > 0) {
      //   obj.fans = $refs.Fans.fans;
      //   obj.fans.cnt = Number(obj.fans.cnt);
      // }
      // // 月发文
      // if ($refs.MonthlyIssue.contentCount.cnt && $refs.MonthlyIssue.contentCount.cnt > 0) {
      //   // alert(1)
      //   obj.contentCount = $refs.MonthlyIssue.contentCount;
      //   obj.contentCount.cnt = Number(obj.contentCount.cnt);
      // }
      // 名称
      if (myInfo.searchType == 0 && myInfo.content.length > 0)
        obj.accountName = myInfo.content;
      //url
      if (myInfo.searchType == 1 && myInfo.content.length > 0)
        obj.domainName = myInfo.content;
      // 主办单位
      if (myInfo.searchType == 2 && myInfo.content.length > 0)
        obj.companyName = myInfo.content;
      // 所在地区
      // if ($refs.areaMany.ownRegion.length > 0) {
      //   obj.provinceIdList = $refs.areaMany.backOwnRegion(0); // 省
      //   obj.cityIdList = $refs.areaMany.backOwnRegion(1); // 市
      //   obj.districtIdList = $refs.areaMany.backOwnRegion(2); // 区
      // }
      // 是否注销
      // if (myInfo.isCancelled == 0 || myInfo.isCancelled == 1 || myInfo.isCancelled == 2) {
      //   obj.isCancelled = myInfo.isCancelled;
      //   if (obj.isCancelled == 2) obj.isCancelled = ''
      // }
      //是否重点监测
      if (myInfo.gatherLevel == 0 || myInfo.gatherLevel == 1) {
        obj.gatherLevel = myInfo.gatherLevel;
      }
      // 导出的时候type == 1
      if (ifReturn) {
        return obj;
      }
      // 非导出正常调用列表
      this.getQueryPage(obj);
    },
    // 重置
    resetFn() {
      // this.$refs.MessageType.status = []; // 平台状态
      // this.$refs.LabelCom.label = [];
      // this.$refs.Fans.fans = { above: true, cnt: null }; // 粉丝
      // (this.$refs.MonthlyIssue.contentCount = {
      //   cnt: null,
      //   above: true,
      // }), // 月发文
        (this.myInfo.content = "");
      // this.$refs.platform_type.getData() 
      // this.$refs.platform_type.resetCom(); // 平台类型
      // this.$refs.body_type.resetCom(); // 主体类型
      // this.$refs.is_authenticate.resetCom(); // 认证情况
      // this.$refs.areaMany.ownRegion = [];
      this.myInfo.searchType = 0
      this.myInfo.gatherLevel = ''//重点监测
      this.myInfo.isCancelled = ''//是否注销
      this.toSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.colRed {
  color: red;
  max-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topJt {
  background: url("~@/assets/img/gjh/topJianTou.png") no-repeat;
  background-position-x: 90%;
  background-position-y: 12px;
}
.bottomJt {
  background: url("../../../assets/img/gjh/bottomJianTou.png") no-repeat;
  background-position-x: 90%;
  background-position-y: 12px;
}
.page {
  display: flex;
  flex-direction: column;
  .account {
    min-width: 120px;
    width: auto;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .account-active {
    color: #f58030;
  }
  .platform-head {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    height: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    .platform-type {
      display: flex;
      min-height: 52px;
      width: 100%;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      border-bottom: 1px solid #e4e6ec;
      .platform-type-left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 16px;
        width: 96px;
        height: 100%;
      }
      .platform-type-right {
        display: flex;
        width: calc(100% - 96px);
        height: 100%;
        .check-all {
          //全选
          margin: 16px 48px 0px 0px;
          ::v-deep .el-checkbox__label {
            color: #333333;
          }
          ::v-deep .el-checkbox__inner {
            border-color: #e4e6ec;
          }
          ::v-deep .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
          ::v-deep .el-checkbox__input.is-indeterminate {
            //半选
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        .checkbox-group {
          width: calc(100% - 100px);
          height: 100%;
          padding-bottom: 16px;
          .check-more {
            //多选
            margin: 16px 48px 0px 0px;
            ::v-deep .el-checkbox__label {
              color: #333333;
            }
            ::v-deep .el-checkbox__inner {
              border-color: #e4e6ec;
            }
          }
          ::v-deep .el-checkbox.check-more.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        ::v-deep .el-radio {
          margin-top: 18px;
          margin-right: 34px;
          .el-radio__label {
            color: #333333;
          }
          .el-radio__inner {
            border-color: #e4e6ec;
          }
          .el-radio__input.is-checked {
            .el-radio__inner {
              background-color: #f58030;
            }
          }
        }
      }
    }
    .fans-box {
      display: flex;
      align-items: center;
      height: 68px;
      width: 100%;
      .fans-num {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 12px;
      }
      .input {
        //输入框
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 190px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .select {
        //下拉选择
        width: 80px;
        height: 36px;
        margin: 0px 24px 0 8px;
        ::v-deep .el-input__inner {
          width: 80px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .screen-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      .select-type {
        width: 120px;
        height: 36px;
        margin-right: 24px;
        ::v-deep .el-input__inner {
          width: 120px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .input-content {
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .address {
        font-size: 14px;
        white-space: nowrap;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 12px 0 24px;
      }
      .select-address {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .query {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 40px;
        cursor: pointer;
      }
      .data-import {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-left: 16px;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
        }
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .account-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    height: 68px;
    .add-account {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 36px;
      background: #f58030;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      img {
        height: 12px;
        width: 12px;
        margin-right: 2px;
      }
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
    .data-box {
      display: flex;
      width: 100%;
      height: 157px;
      padding: 24px 0px;
      border-bottom: 1px solid #e4e6ec;
      .disposed {
        height: 114px;
        width: 129px;
        position: absolute;
        right: 138px;
      }
      .data-box-left {
        width: 154px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 56px;
          width: 56px;
          // border-radius: 50%;
        }
        .platform-type {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: auto;
          background: #f58030;
          // max-width: 104px;
          padding: 0px 22px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: 9px;
        }
      }
      .data-box-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(100% - 104px);
        height: 100%;
        .name-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 25px;
          margin-bottom: 16px;
          .name {
            height: 25px;
            width: 80%;
            line-height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .bad-num {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            width: 20%;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e60b1e;
          }
        }
        .data-info {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
          .status {
            color: #02bc7c;
            // margin-right: 46px;
          }
          .region {
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .subject-type {
            margin-right: 46px;
          }
          .company {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .fnas {
            margin-right: 46px;
          }
        }
        .operation {
          display: flex;
          height: 32px;
          width: 100%;
          .operation-left {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 8px;
            }
            .select {
              //下拉选择
              width: 104px;
              height: 32px;
              ::v-deep .el-input__inner {
                width: 104px;
                height: 32px;
                border-radius: 4px;
              }
              ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-input__icon.el-icon-arrow-down {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-input__icon.el-icon-circle-close {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-cascader {
                height: 32px !important;
                margin-top: 0px;
                .el-input.el-input--suffix {
                  height: 32px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
          .operation-right {
            // position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 80%;
            height: 100%;
            .detail {
              display: flex;
              height: 100%;
              width: auto;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              margin-right: 24px;
              img {
                height: 14px;
                width: 13px;
                margin-right: 7px;
              }
            }
          }
          .programme {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 14px;
              width: 15px;
              margin-right: 7px;
            }
          }
          .remove {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e60b1e;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}
.zhuxiao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tit {
    margin-right: 12px;
  }
}
</style>